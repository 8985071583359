var site = site || {};
var generic = generic || {};

(function ($, Drupal, AccessibleDisclosureButton) {
  var contentBlockSitewideBanner = {
    init: function (context) {
      var self = this;
      var disclosureButton;
      var $body = $('body');
      var observer = $();
      var moduleHeight = 0;
      var moduleCookieName = '';
      var hasModuleCookie = false;
      var $headerClass = $('.js-site-header');
      var $headerInner = $headerClass.find(
        '.js-site-header-formatter-inner, .js-site-header-fixed-wrapper'
      );
      var $apptMenu = $('.appointment-nav');
      var $newsLetter = $('.js-content-block-signup');

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.$module = $('.js-content-block-sitewide-banner--v1', context);
      self.$moduleWrapper = self.$module.find('.js-content-block-sitewide-banner__content-wrapper');
      self.$content = self.$module.find('.js-content-block-signup');
      self.$triggers = $('.js-content-block-sitewide-banner-trigger', self.$module);
      if (self?.$triggers) {
        disclosureButton = new AccessibleDisclosureButton(self.$triggers.get(0));
      }

      if (self.$module.length < 1 || self.$content.length < 1) {
        return false;
      }
      moduleCookieName = self.$module.data().moduleCookieName;
      hasModuleCookie = $.cookie(moduleCookieName);
      if (hasModuleCookie) {
        self.$module.detach();

        return;
      }
      self.$module.removeClass('hidden');
      if (disclosureButton?.domNode) {
        disclosureButton.init();
      }

      $body.addClass('content-block-sitewide-banner--enabled');
      $headerClass.addClass('site-wide-banner--enabled');
      self.$moduleWrapper.css('margin-top', $headerInner.height());

      if (Number(site.client.isMobile) && $apptMenu.length > 0 && $newsLetter) {
        self.$moduleWrapper.css('margin-top', $headerInner.height() + $apptMenu.height());
      }

      moduleHeight = self.$module.outerHeight(true);
      $(document).trigger('contentBlock.sitewideBanner', [moduleHeight]);

      if (self.$triggers.data('expires')) {
        observer = new MutationObserver(function (mutationList) {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
              $body.removeClass('content-block-sitewide-banner--enabled');
              $headerClass.removeClass('site-wide-banner--enabled');
              $(document).trigger('contentBlock.sitewideBanner', [0]);
              self.setCookie();
              self.$module.detach();
            }
          });
        });
        observer.observe(self.$triggers.get(0), {
          childList: false,
          attributes: true
        });
      }
    },
    setCookie: function () {
      var self = this; /* eslint no-shadow: ["error", { "allow": ["done"] }] */

      $.cookie(self.$triggers.attr('aria-controls'), 1, {
        path: '/',
        expires: self.$triggers.data('expires')
      });
    },
    initTriggers: function ($triggers) {
      var triggerAttrs = $triggers[0].attributes;

      for (i = 0; i < triggerAttrs.length; i++) {
        if (triggerAttrs[i].nodeName !== 'class') {
          $($triggers[i + 1]).attr(triggerAttrs[i].nodeName, triggerAttrs[i].nodeValue);
        }
      }
      $triggers.each(function () {
        disclosureButton = new AccessibleDisclosureButton(this);
        disclosureButton.init();
      });
    }
  };

  /* eslint-disable no-param-reassign */
  Drupal.behaviors.contentBlockSitewideBannerV1 = {
    $module: $(),
    $triggers: $(),
    attached: false,
    attach: function (context) {
      var handle = null;
      var showSignupPopup;

      if (Drupal.settings.globals_variables.display_signup_popup_after_accept_cookie) {
        showSignupPopup = function () {
          var $onetrustButton = $('#onetrust-button-group button');

          $onetrustButton.on('click', function () {
            /* EMEAFR-178 - Show the popup 5 second delay. */
            var popupTimeout = setTimeout(contentBlockSitewideBanner.init(context), 5000);

            clearTimeout(popupTimeout);

            if (generic.cookie('OptanonAlertBoxClosed')) {
              clearInterval(handle);
              handle = 0;
            }
          });
          if (
            generic.cookie('OptanonAlertBoxClosed') &&
            !generic.cookie('content_block_sitewide_banner_1')
          ) {
            clearInterval(handle);
            handle = 0;
            contentBlockSitewideBanner.init(context);
          }
        };
        handle = setInterval(showSignupPopup, 1500);
      } else {
        contentBlockSitewideBanner.init(context);
      }
    }
  };

  $(document).on('contentBlock.signupSuccess', function cookieVal() {
    contentBlockSitewideBanner.setCookie();
  });
})(jQuery, Drupal, window.AccessibleDisclosureButton);
